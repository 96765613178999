
import { ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';



export class FormValidators {

    static requireOneOfTwo(firstControlKey: string, secondControlKey: string, validRegExp: string) {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            let firstControl = group.controls[firstControlKey];
            let secondControl = group.controls[secondControlKey];

            let _regEx = new RegExp(validRegExp);

            if (firstControl.value === ''  && secondControl.value === '') {
                return { bothRequired: true };
            }
            return null;
        }
    }

}