import { environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest.clone({ setHeaders: {
        'Api-Key': environment.authentication.apiKey,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      } }));
  }
}
