import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {EventsComponent} from './events.component';
import {EventListingComponent} from './event-listing/event-listing.component';
import {EventResolver} from './event.resolver';
import {PendingChangesGuard} from '../shared/core/guards/app.pending-changes-guard';
import {PopoverModule} from '../shared/lib/ngx-popover';


const routes: Routes = [
  {
    path: '',
    component: EventListingComponent,
    resolve: {event: EventResolver}
  },
  {
    path: 'events/:id',
    resolve: {event: EventResolver},
    loadChildren: () => import('../registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    'path': '**',
    'redirectTo': '',
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(routes),
    PopoverModule
  ],
  exports: [RouterModule, PopoverModule],
  providers: [EventResolver, PendingChangesGuard]
})


export class EventsRoutingModule {}


export const EventsRoutedComponents = [EventsComponent, EventListingComponent]
