import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {EventsService} from '../events.service';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {IEvent} from 'app/shared/models/event';



@Component({
  selector: 'event-banner',
  templateUrl: './event-banner.component.html',
  styleUrls: ['./event-banner.component.scss']
})
export class EventBannerComponent implements OnInit {
  selectedEvent: IEvent;
  customImageUrl: any;
  bannerText: string;


  constructor(
    private _eventsService: EventsService,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) {}


  ngOnInit() {
    this.selectedEvent = this._eventsService.getSelectedEvent();

    if (this.selectedEvent) {
      if (this.selectedEvent.banner != null && this.selectedEvent.banner.imageUrl !== '') {
        this.customImageUrl = this._sanitizer.bypassSecurityTrustStyle('url({0})'.formatWith(this.selectedEvent.banner.imageUrl));
      }

      this.bannerText = this.selectedEvent.banner != null && this.selectedEvent.banner.text !== ''
        ? this.selectedEvent.banner.text
        : this.selectedEvent.name;
    }
  }


  getFormattedDateRange() {
    if (this.selectedEvent == null) {
      return null;
    }

    const datePipe = new DatePipe('en-US');
    const startMonth = datePipe.transform(this.selectedEvent.startTime, 'MMMM');
    const endMonth = datePipe.transform(this.selectedEvent.endTime, 'MMMM');
    const startDay = datePipe.transform(this.selectedEvent.startTime, 'd');
    const endDay = datePipe.transform(this.selectedEvent.endTime, 'd');

    if (startMonth === endMonth) {
      if (startDay === endDay) {
        return startMonth + ' ' + startDay;
      }

      return startMonth + ' ' + startDay + ' - ' + endDay;
    } else {
      return startMonth + ' ' + startDay + ' - ' + endMonth + ' ' + endDay;
    }
  }
}
