import { NgModule, ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './shared/core/handlers/global-error.handler';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EventsModule } from './events/events.module';
import { RegistrationModule } from './registration/registration.module';
import { NotificationsService } from './shared/core/notifications/notifications.service';
import { StorageService } from './shared/core/storage/storage.service';
import { LoggingService } from './shared/core/logging/logging.service';
import { Angulartics2Module} from 'angulartics2';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {ConfirmationService} from 'primeng/api';
import {HttpClientModule} from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    EventsModule,
    RegistrationModule,
    HttpClientModule,
    Angulartics2Module.forRoot([Angulartics2GoogleTagManager]),
    NgbModule
  ],
  exports: [
    SharedModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    StorageService,
    ConfirmationService,
    NotificationsService,
    LoggingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
