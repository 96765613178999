import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EventsService } from './events.service';
import { NotificationsService } from '../shared/core/notifications/notifications.service';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin as observableForkJoin} from 'rxjs/internal/observable/forkJoin';



@Injectable()
export class EventResolver implements Resolve<any> {
  constructor(
    private _eventsService: EventsService,
    private _notificationService: NotificationsService,
    private _router: Router
  ) {}


  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const eventId = route.params.id;
    const eventCode = route.params.eventCode;

    if (eventId) {
      const getEvent = this._eventsService.getEvent(eventId);
      const getEventOptions = this._eventsService.getEventOptions(eventId);
      const getEventQuestions = this._eventsService.getEventQuestions(eventId);
      const getEventSessions = this._eventsService.getEventSessions(eventId);
      const getTestimonials = this._eventsService.getEventTestimonials(eventId);
      const getSpeakers = this._eventsService.getEventSpeakers(eventId);

      return observableForkJoin([getEvent, getEventOptions, getEventQuestions, getEventSessions, getTestimonials, getSpeakers]).pipe(
        map(results => {
          const event: any = results[0];

          if (event) {
            event.options = results[1];
            event.questions = results[2];
            event.sessions = results[3];
            event.testimonials = results[4];
            event.speakers = results[5];
            return event;
          } else {
            setTimeout(() => this._notificationService.notify('error', 'Error', 'Event is invalid or not available for registration.'), 1);
            this._router.navigate(['/']);
          }
        }
      ));
    }

    if (eventCode) {
      const getEvent = this._eventsService.findEventByCode(eventCode);

      return observableForkJoin([getEvent]).pipe(
        map(results => {
          const event = results[0];

          if (event) {
            this._eventsService.getEventOptions(event.id).subscribe(data => event.options = data);
            this._eventsService.getEventQuestions(event.id).subscribe(data => event.questions = data);
            this._eventsService.getEventSessions(event.id).subscribe(data => event.sessions = data);
            this._eventsService.getEventTestimonials(event.id).subscribe(data => event.testimonials = data);
            return event;
          } else {
            setTimeout(() => this._notificationService.notify('error', 'Error', 'Event is invalid or not available for registration.'), 1);
            this._router.navigate(['/']);
          }
        })
      );
    }

    // Unset Event if not on an event page
    if (!eventCode && !eventId) {
      this._eventsService.unsetEvent();
    }
  }
}
