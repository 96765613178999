import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { NotificationsComponent } from './core/notifications/notifications.component';
import { HeaderComponent } from 'app/header/app-header.component';
import { EventBannerComponent } from '../events/event-banner/event-banner.component';
import { FooterComponent } from 'app/footer/app-footer.component';
import {RequestInterceptorService} from './core/extensions/request-interceptor.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {MessagesModule} from 'primeng/messages';
import {ToastModule} from 'primeng/toast';
import {InputMaskModule} from 'primeng/inputmask';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

const PrimeNgImports = [MessagesModule, ToastModule, InputMaskModule, ConfirmDialogModule];
const YnetComponentImports = [NotificationsComponent];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        CoreModule,
        PrimeNgImports
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        EventBannerComponent,
        YnetComponentImports
    ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptorService,
        multi: true,
      },
    ],
    exports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        CoreModule,
        HeaderComponent,
        FooterComponent,
        PrimeNgImports,
        EventBannerComponent,
        YnetComponentImports
    ],

})
export class SharedModule { }


