import { EventsService } from '../../../events/events.service';
import { RegistrationService } from '../../../registration/registration.service';
import { Observable, of, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';




export class AsyncValidators {
    public static delay = 500;
    static createPromoCodeValidatorTimeout;
    static createMembershipValidatorTimeout;

    static createPromoCodeValidator(eventService: EventsService) {
      return function(control) {
        clearTimeout(AsyncValidators.createPromoCodeValidatorTimeout);

        return new Promise((resolve, reject) => {
          if (!control.value || control.value.length === 0) return resolve(null);

          AsyncValidators.createPromoCodeValidatorTimeout = setTimeout(() => {
            eventService.checkPromoCode(eventService.getSelectedEvent().id, control.value).subscribe(result => {
              if (result == null || !result.isValid) {
                resolve({invalid: true});
              } else {
                resolve(null);
              }
            });
          }, AsyncValidators.delay);
        });
      };
    }







    static createMembershipValidator(eventService: EventsService, registrationService: RegistrationService) {
        return function(control) {
            clearTimeout(AsyncValidators.createMembershipValidatorTimeout);
            return new Promise((resolve, reject) => {
                if (!control.value || control.value.length === 0) {
                    return resolve(null);
                }
                AsyncValidators.createMembershipValidatorTimeout = setTimeout(() => {
                    registrationService.checkMembership(eventService.getSelectedEvent().id, control.value).subscribe(
                        result => {
                        if (result == null || !result.isValid) {
                            // TODO: need to return something if not ok
                            result.isInvalid = true;
                            resolve(result);
                        } else {
                            // TODO: need to return null if ok
                            resolve(null);
                        }
                    });
                }, AsyncValidators.delay);
            });
        };
    }
}
