
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ISession } from 'app/shared/models/event.session';



@Component({
    selector: 'app-session-preference',
    templateUrl: './session-preference.component.html'
}) export class SessionPreferenceComponent {
    @Input('session') public session: ISession;
    @Input('sessionPreferenceForm') sessionPreferenceForm: UntypedFormGroup;


    constructor() {}
}
