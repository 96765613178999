// export const environment = {
//   production: true,
//   authentication: {
//     apiKey: '#{ApiKey}'
//   },
//   api: {
//     v1: '#{ApiBaseUrl}' + '/' + '#{ApiVersion}'
//   }
// };


export const environment = {
  production: true,
  authentication: {
    apiKey: '14342472d445c84bb8bd8910c4f6b29e0698b421753e652151349c9498797075'
  },
  api: {
    v2: 'https://dev-api-em3.cufi.org/v2',
  }
};
