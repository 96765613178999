<div class="row g-2">
  <div *ngFor="let question of questions; let i=index" class="col-12">
    <app-registration-question
      [question]="question"
      [mainForm]="mainForm"
      [answers]="answers"
      [questionForm]="questionsForm.controls[i]"
    ></app-registration-question>
  </div>
</div>
