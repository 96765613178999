import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { RegistrationService } from '../registration.service';
import { ILookup } from 'app/shared/models/lookup';
import {Observable} from 'rxjs/internal/Observable';



@Component({
  selector: 'form-address',
  templateUrl: './address.component.html'
})
export class AddressComponent implements OnInit {
  @Input('addressForm') addressForm: UntypedFormGroup;
  @Input() mainForm: NgForm;
  private static id: number = 0;
  componentId: number = 0;
  states: Observable<ILookup[]>;
  countries: Observable<ILookup[]>;

  constructor(
    private _registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.componentId = ++AddressComponent.id; // Create customId for template use
    this.states = this._registrationService.getStatesByCountryCode('US');
    this.countries = this._registrationService.getCountries();
  }

  countryChange(val: string) {
    this.states = this._registrationService.getStatesByCountryCode(val);
  }
}
