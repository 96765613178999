import { Injectable } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validator, Validators, FormControl } from '@angular/forms';
import { EventsService } from '../events/events.service';
import { RegistrationService } from './registration.service';
import { AsyncValidators } from '../shared/core/validators/app.async-validators';
import { FormValidators } from '../shared/core/validators/form.validators';
import { IEvent } from 'app/shared/models/event';
import { AddressType } from 'app/shared/models/address';
import { IQuestion, QuestionTypes } from 'app/shared/models/question';
import { IOption } from 'app/shared/models/event.option';
import { ISession } from 'app/shared/models/event.session';



@Injectable()
export class RegistrationFormBuilder {
  private questionTypes = QuestionTypes;


  constructor(
    private _fb: UntypedFormBuilder,
    private _eventsService: EventsService,
    private _registrationService: RegistrationService
  ) {}


  build(event: IEvent): UntypedFormGroup {
    return this._fb.group({
      referenceNumber: [''],
      title: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mealPreference: ['0', Validators.required],
      email: ['',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$')
        ]
      ],
      phone: this._fb.group({ mobile: [''], home: [''] }, { validator: FormValidators.requireOneOfTwo('mobile', 'home', '/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g') }),
      residence: this._fb.group({
        type: AddressType.residence,
        address1: ['', [Validators.required, Validators.pattern(/^(?!\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b)/i)]],
        address2: '',
        city: ['', Validators.required],
        state: ['', Validators.required],
        postalCode: ['', Validators.required],
        country: ['US', Validators.required]
      }),
      mailing: this._fb.group(this.getMailingAddressFormModel()),
      answers: this._fb.array(this.buildQuestionsForm(event.questions)),
      options: this._fb.array(this.buildOptionsForm(event.options)),
      sessionPreferences: this._fb.array(this.buildSessionPreferencesForm(event.sessions.filter(s => s.options.length > 1))),
      schoolName: ['', event.settings && event.settings.studentEvent ? Validators.required : Validators.nullValidator],
      memberId: ['', null, AsyncValidators.createMembershipValidator(this._eventsService, this._registrationService)],
      promoCode: ['', null, AsyncValidators.createPromoCodeValidator(this._eventsService)],
      birthDate: ['', Validators.nullValidator],

    });
  }


  buildQuestionsForm(questions: Array < IQuestion > ) {
    return questions.map(q => {
      let group = this._fb.group({
        questionId: [q.id],
        choiceId: [null],
        yesNoAnswer: [null],
        shortAnswer: [null],
      });

      let choiceAnswerControl = group.get("choiceId");
      let yesNoAnswerControl = group.get("yesNoAnswer");
      let shortAnswerControl = group.get("shortAnswer");

      if (q.questionType === this.questionTypes.FreeText) {
        shortAnswerControl.setValidators([Validators.required]);
      } else if (q.questionType === this.questionTypes.MultipleChoice) {
        choiceAnswerControl.setValidators([Validators.required]);
      } else if (q.questionType === this.questionTypes.TrueFalse) {
        yesNoAnswerControl.setValidators([Validators.required]);
      }

      return group;
    });
  }


  buildOptionsForm(options: Array < IOption > ) {
    return options.map(o => this._fb.group({
      isSelected: [false],
      id: [o.id]
    }));
  }


  buildSessionPreferencesForm(sessions: Array < ISession > ) {
    // Should only be sessions with 2+ options (preference)
    return sessions.map(s => this._fb.group({
      sessionId: [s.id],
      sessionOptionId: ['', Validators.required]
    }));
  }


  getMailingAddressFormModel() {
    return {
      type: AddressType.mailing,
      useResidenceAddress: false,
      address1: ['', Validators.required],
      address2: '',
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.required],
      country: ['US', Validators.required]
    };
  }
}
