export enum QuestionTypes {
    MultipleChoice = 1,
    TrueFalse = 2,
    FreeText = 3
}


export interface IQuestion {
    id: string;
    questionType: QuestionTypes;
    text: string;
    sequence: number;
    startTime: Date;
    endTime: Date;
    choices: Array<IChoice>;
    choiceAnswer: IChoice;
    yesNoAnswer: boolean;
    shortAnswer: string;    
}

export interface IChoice {
    id: string;
    text: string;
    sequence: number;
    description: string;
    isDefaultWebChoice: boolean;
    isDefaultClientChoice: boolean;
}
