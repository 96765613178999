import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IEvent } from 'app/shared/models/event';
import { IPrice } from 'app/shared/models/event.price';
import { ITestimony } from 'app/shared/models/event.testimony';
import { ISession } from 'app/shared/models/event.session';
import { IQuestion } from 'app/shared/models/question';
import { IOption } from 'app/shared/models/event.option';
import { ISpeaker } from 'app/shared/models/event.speaker';
import { Angulartics2 } from 'angulartics2';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';



@Injectable()
export class EventsService {
    private selectedEvent: IEvent;


    constructor(
      private http: HttpClient,
      private angulartics2: Angulartics2
    ) {}


    setSelectedEvent(event: IEvent) {
      this.selectedEvent = event;
      this.angulartics2.eventTrack.next({
        action: 'selected',
        properties: {
          category: 'event',
          label: event.name,
          value: event.id
        }
      });
    }


    unsetEvent() {
      this.selectedEvent = null;
    }


    getSelectedEvent(): IEvent {
      return this.selectedEvent;
    }


    getEvents() {
      return <Observable<IEvent[]>>this.http.get(environment.api.v2 + '/events').pipe(
        map(res => <IEvent[]>res)
      );
    }


    findEventByCode(eventCode: string) {
      this.angulartics2.eventTrack.next({
        action: 'searched',
        properties: {
          category: 'event',
          label: eventCode
        }
      });

      return <Observable<IEvent>>this.http.get(environment.api.v2 + '/events?code={0}'.formatWith(eventCode)).pipe(
        map(res => {
          const result = <IEvent[]>res;

          if (result.length > 0) {
            this.angulartics2.eventTrack.next({
              action: 'found',
              properties: {
                category: 'event',
                label: eventCode
              }
            });

            return result[0];
          }

          return null;
        })
      );
    }


    // TODO:  Save event to here!!!  NULL it out at home
    getEvent(eventId: string) {
      return <Observable<IEvent>>this.http.get(environment.api.v2 + '/events/{0}'.formatWith(eventId)).pipe(
        map(res => {
          const event = <IEvent>res;
          this.selectedEvent = event;
          return event;
        })
      );
    }


    getEventPricing(eventId: string): Observable<Array<IPrice>>  {
      return <Observable<IPrice[]>>this.http.get(environment.api.v2 + '/events/{0}/prices'.formatWith(eventId)).pipe(
        map((res) => <IPrice[]>res)
      );
    }


    getEventTestimonials(eventId: string): Observable<Array<ITestimony>> {
      return <Observable<ITestimony[]>>this.http.get(environment.api.v2 + '/events/{0}/testimonies'.formatWith(eventId)).pipe(
        map(res => <ITestimony[]>res)
      );
    }


    getEventSessions(eventId: string): Observable<Array<ISession>> {
      return <Observable<ISession[]>>this.http.get(environment.api.v2 + '/events/{0}/sessions'.formatWith(eventId)).pipe(
        map(res => <ISession[]>res)
      );
    }


    getEventQuestions(eventId: string): Observable<Array<IQuestion>> {
      return <Observable<IQuestion[]>>this.http.get(environment.api.v2 + '/events/{0}/questions'.formatWith(eventId)).pipe(
        map(res => <IQuestion[]>res)
      );
    }


    getEventOptions(eventId: string): Observable<Array<IOption>> {
      return <Observable<IOption[]>>this.http.get(environment.api.v2 + '/events/{0}/options'.formatWith(eventId)).pipe(
        map(res => <IOption[]>res)
      );
    }


    getEventSpeakers(eventId: string): Observable<Array<ISpeaker>> {
      return <Observable<ISpeaker[]>>this.http.get(environment.api.v2 + '/events/{0}/speakers'.formatWith(eventId)).pipe(
        map(res => <ISpeaker[]>res)
      );
    }


    checkPromoCode(eventId: string, code: string): Observable<any> {
      return <Observable<any>>this.http.post(environment.api.v2 + '/events/{0}/prices/promoCode'.formatWith(eventId), {promoCode: code}).pipe(
        map(res => <any>res)
      );
    }
}
