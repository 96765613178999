import { ResidenceAddress, MailingAddress } from './address';

export class Attendee {
    registrationId: string;
    referenceNumber: string;
    registrationReferenceNumber: string;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    mealPreference: string;
    mobile: string;
    home: string;
    residence: ResidenceAddress;
    mailing: MailingAddress;
    answers: Array<IAnswer>;
    options: Array<string>;
    sessionPreferences: Array<ISessionPreference>;

    schoolName: string;
    memberId: string;
    promoCode: string;
    price: number;
    premium: number;
    total: number;
    isSelected: boolean;
    addressesAreTheSame: boolean;
    birthDate: Date;

    constructor() {
        this.residence = new ResidenceAddress();
        this.mailing = new MailingAddress();
        this.answers = [];
        this.isSelected = false;
    }

    public addAnswer(answer: IAnswer) {
        if (answer) {
          this.answers.push(answer);
        }
    }
}

export interface IAnswer {
    questionId: string;
    choiceId: string;
    shortAswer: string;
    yesNoAnswer: boolean;
}


export interface ISessionPreference {
    sessionId: string;
    sessionOptionId: string;
}
