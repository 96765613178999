import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    private _storage = sessionStorage;

    constructor() { }

    getItem(key: string) {
        return JSON.parse(this._storage.getItem(key)) || [];
    }

    setItem(key: string, value: any) {
        this._storage.setItem(key, JSON.stringify(value));
    }

    removeItem(key: string): void {
        this._storage.removeItem(key);
    }

    clear() {
        this._storage.clear();
    }
}
