import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

type logTypes = 'Error' | 'Warning' | 'Debug' | 'Info';

@Injectable()
export class LoggingService {

    constructor(private http: HttpClient) { }
    log(logType: logTypes, message: string, url?: string, stack?: string) {
        return this.http
            .post(environment.api.v2 + '/logs', {
                logType,
                message,
                url,
                stack
            })
            .subscribe(
            x => {},
            err => console.log(err));
    }
}
