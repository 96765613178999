<ng-container [formGroup]="questionForm">
  <input type="hidden" formControlName="questionId">



  <ng-container *ngIf="question.questionType === 1">
    <label class="form-label">{{question.text}}</label>
    <select class="form-select" formControlName="choiceId">
      <option value="">Select Answer</option>
      <option *ngFor="let choice of question.choices" [value]="choice.id">{{choice.text}}</option>
    </select>
  </ng-container>


  <ng-container *ngIf="question.questionType === 2">
    <input type="hidden" formControlName="yesNoAnswer" (change)="onYesNoAnswerChanged($event)" />
    <label class="form-label">{{question.text}}</label>
    <div>
      <div class="form-check form-check-inline">
        <input
          #yesRadio
          type="radio"
          id="yesQuestion_{{question.id}}"
          [(ngModel)]="answer.yesNoAnswer"
          [ngModelOptions]="{standalone: true}"
          [value]="true"
          name="yesNoAnswer_{{question.id}}"
          class="form-check-input"
          (change)="onRadioSelectionChanged(true)">
        <label class="form-check-label" for="yesQuestion_{{question.id}}">Yes</label>
      </div>

      <div class="form-check form-check-inline">
        <input
          #noRadio
          type="radio"
          id="noQuestion_{{question.id}}"
          [(ngModel)]="answer.yesNoAnswer"
          [ngModelOptions]="{standalone: true}"
          [value]="false"
          name="yesNoAnswer_{{question.id}}"
          class="form-check-input"
          (change)="onRadioSelectionChanged(false)">
        <label class="form-check-label" for="noQuestion_{{question.id}}">No</label>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="question.questionType === 3">
    <label class="form-label">{{question.text}}</label>
    <input type="text" class="form-control" formControlName="shortAnswer">
  </ng-container>
</ng-container>




<!--
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
    Default radio
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    Default checked radio
  </label>
</div>
-->
