export interface IAddress {
    type: AddressType;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
}

export enum AddressType {
    residence = 1,
    mailing = 2
}

export class ResidenceAddress implements IAddress {
    type: AddressType = AddressType.residence;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
}

export class MailingAddress implements IAddress {
    type: AddressType = AddressType.mailing;
    useResidenceAddress: boolean;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
}

export interface ICopyAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
}
