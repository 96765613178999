import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RegistrationFormBuilder} from './registration-form-builder';
import {RegistrationRoutingModule, RegistrationRoutedComponents} from './registration-routing.module';
import {RegistrationService} from './registration.service';
import {FormsModule} from '@angular/forms';
import {ConfirmationService} from 'primeng/api';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  imports: [
    SharedModule,
    RegistrationRoutingModule,
    FormsModule,
    NgbModule
  ],
  declarations: [
    RegistrationRoutedComponents
  ],
  providers: [
    RegistrationFormBuilder,
    ConfirmationService,
    RegistrationService
  ]
})
export class RegistrationModule {}



