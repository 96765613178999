import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, ...args: any[]) : string {
    if (!value){
      return value;
    }
    let limit = args.length > 0 ? parseInt(args[0], 10) : 50;
    let trail = args.length > 1 ? args[1] : '...';
    let shouldTrim = value.length > limit;
    if (!shouldTrim){
        return value;
    }
    let trimmedString = value.substring(0, limit);
    return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + trail;
  }
}