import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EventsService } from '../events.service';
import { UntypedFormBuilder, UntypedFormGroup, Validator, Validators, FormControl } from '@angular/forms';
import { IEvent } from 'app/shared/models/event';



class EventGroup {
  year: string;
  events: Array<IEvent>;

  public constructor(year: string) {
    this.year = year;
    this.events = [];
  }
}



@Component({
  selector: 'app-event-listing',
  templateUrl: './event-listing.component.html',
  styleUrls: ['./event-listing.component.scss']
})
export class EventListingComponent implements OnInit {
  events: Array<IEvent>;
  eventGroups: Array<EventGroup> = [];
  privateEventCode = '';
  privateEventForm: UntypedFormGroup;
  privateEventNotFound: boolean;
  loadingEvents: boolean = true;


  constructor(
    private _eventsService: EventsService,
    private _fb: UntypedFormBuilder,
    private _router: Router
  ) {
    this.loadingEvents = true;
  }


  ngOnInit() {
    this.privateEventForm = this._fb.group({code: ['', Validators.required]});

    this._eventsService.getEvents().subscribe(events => {
      let datePipe = new DatePipe('en-US');
      let currentYear = '0';
      let currentGroup: EventGroup;

      events.forEach(event => {
        const eventYear = datePipe.transform(event.startTime, 'yyyy');

        if (eventYear !== currentYear) {
          currentGroup = new EventGroup(eventYear);
          currentYear = eventYear;
          this.eventGroups.push(currentGroup);
        }

        currentGroup.events.push(event);
      });

      this.loadingEvents = false;
    });
  }


  findPrivateEvent(eventCode: string) {
    this.privateEventCode = '';
  }


  submitEventCode({value, valid}: {value: any, valid: boolean}) {
    this._eventsService.findEventByCode(value.code).subscribe(event => {
      this.privateEventNotFound = event == null;

      if (event == null) return;

      this._router.navigateByUrl('/events/' + event.id);
    }, error => {
      this.privateEventNotFound = true;
    });
  }
}
