<event-banner></event-banner>



<main role="main">
  <div class="container mt-4">

    <div class="text-center p-5 text-secondary opacity-25" *ngIf="loadingEvents">
      <i class="fa fa-spinner fa-spin fa-3x"></i>
    </div>


    <p *ngIf="!loadingEvents && (!eventGroups || !eventGroups.length)" class="text-center mt-5">Sorry, no scheduled events at this time.</p>


    <div class="row justify-content-md-center g-5 pt-3 pb-3">
      <ng-container *ngFor="let eventGroup of eventGroups">
        <div class="col-12 col-md-6 col-lg-4" *ngFor="let event of eventGroup.events">
          <div class="card text-center">
            <div class="card-header">
              <span>{{ event.startTime | moment: 'MMM' }}</span> &bull;
              <span>{{ event.startTime | moment: 'DD' }}</span> &bull;
              <span>{{ event.startTime | moment: 'YYYY' }}</span>
            </div>

            <div class="card-body">
              <h3 class="card-title">{{ event.name }}</h3>

              <p class="card-text">
                <i class="fa fa-map-marker"></i>
                {{ event.location }}
              </p>

              <p class="card-text text-start">
                <em>{{ event.summary | truncate : 200 }}</em>
              </p>

              <a [routerLink]="['events', event.id]" class="btn btn-primary">Register</a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>


    <hr>


    <form class="privateEvent" [formGroup]="privateEventForm" novalidate (ngSubmit)="submitEventCode(privateEventForm)">
      <label class="form-label">Register for a Private Event</label>
      <input class="form-control" type="text" placeholder="Private Event Code" formControlName="code">
      <p *ngIf="privateEventNotFound" class="text-danger mt-2" role="alert">Sorry, event code is invalid.</p>
      <button class="btn btn-primary mt-2" type="submit" [disabled]="privateEventForm.invalid">Register</button>
    </form>
  </div>
</main>
