interface String {
    formatWith(...replacements: string[]): string;
}

if (!String.prototype.formatWith) {
  String.prototype.formatWith = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match: string, number: number) { 
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}