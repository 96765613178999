import { AbstractControl, UntypedFormGroup, NgForm } from '@angular/forms';

export abstract class FormBaseClass {

    form: UntypedFormGroup;

    constructor() { }

    hasErrors(controlPath: string, mainForm:NgForm): boolean {
        const control: AbstractControl = this.form.get(controlPath);
        if (control !== undefined && control !== null) {
            //return control.errors && control.touched;

            return control.errors != null && (mainForm == null? control.touched : mainForm.submitted);
        }
        return false;
    }

    hasError(errorCode: string | Array<string>, controlPath: string, mainForm:NgForm): boolean {
        const control: AbstractControl = this.form.get(controlPath);
        if (control !== undefined && control !== null) {
            if (!(mainForm == null? control.touched : mainForm.submitted)) {
                return false;
            }
            return (errorCode instanceof Array)
                ? errorCode.some(x => control.hasError(x))
                : control.hasError(errorCode);
        }
    }
}

