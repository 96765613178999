export interface IPrice {
    id: string;
    type: string;
    title: string;
    description: string;
    amount: number;
    startTime: Date;
    endTime: Date;
    listingGroupName: string;
    listingGroupSequence: number;
    listingGroupUnitDescription: string;
}

export class PriceType {
    static standard = 'standard';
    static member = 'member';
    static group = 'group';
}



export class PriceGroup {
    public constructor(){
        this.prices = [];
    }
    
    priceId: string;
    description: string;
    unitDescription: string;
    sequence: number;
    prices: IPrice[];
    memberPrice: IPrice;
    nonMemberPrice: IPrice;
}
