
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IOption } from 'app/shared/models/event.option';



@Component({
  selector: 'app-registration-option',
  templateUrl: './registration-option.component.html'
}) export class RegistrationOptionComponent implements OnInit {
    @Input('option') public option: IOption;
    @Input('optionForm') optionForm: UntypedFormGroup;
    private static id: number = 0;
    componentId: number = 0;


    constructor() {}

    ngOnInit() {
      this.componentId = ++RegistrationOptionComponent.id; // Create customId for template use
    }
}
