import {Component, OnInit, OnDestroy, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, NgForm} from '@angular/forms';
import {ComponentCanDeactivate} from '../shared/core/guards/app.pending-changes-guard';
import {IEvent} from 'app/shared/models/event';
import {IRegistration} from 'app/shared/models/registration';
import {AddressType, ICopyAddress} from 'app/shared/models/address';
import {Attendee} from 'app/shared/models/attendee';
import {IOption} from 'app/shared/models/event.option';
import {FormBaseClass} from 'app/shared/FormBaseClass';
import {RegistrationFormBuilder} from './registration-form-builder';
import {StorageService} from '../shared/core/storage/storage.service';
import {EventsService} from '../events/events.service';
import {RegistrationService} from './registration.service';
import {ISession} from 'app/shared/models/event.session';
import {PriceGroup, IPrice} from 'app/shared/models/event.price';
import {DatePipe} from '@angular/common';
import {QuestionsListComponent} from './questions-component/registration-questions-list.component';
import {ConfirmationService} from 'primeng/api';
import {Observable} from 'rxjs/internal/Observable';
import {Subscription} from 'rxjs/internal/Subscription';



@Component({
  selector: 'app-event-registration',
  templateUrl: './registration.component.html',
  encapsulation: ViewEncapsulation.None
}) export class RegistrationComponent extends FormBaseClass implements OnInit, OnDestroy, ComponentCanDeactivate {
  private _registrationSubscription: Subscription;
  private _mailingAddressSameAsResidenceAddress: Subscription;
  priceGroups: PriceGroup[];
  datePipe = new DatePipe('en-US');
  formTitle = 'New Attendee';
  titles: Observable<string[]>;
  mealPreferences: Observable<any[]>;
  addressType = AddressType; // NOTE: Exposes AddressType enum to view
  hasEventInfo = false;
  event: IEvent;
  registration: IRegistration;
  processing = false;
  sessionsWithPreferences: ISession[];


  @ViewChild('uxpQuestionList') uxpQuestionList: QuestionsListComponent;
  @ViewChild('mainForm', {static: true}) mainForm: NgForm;


  constructor(
    private _fb: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _storageService: StorageService,
    private _eventsService: EventsService,
    private _registrationService: RegistrationService,
    private _registrationFormBuilder: RegistrationFormBuilder,
    private _confirmationService: ConfirmationService
  ) {
    super();
  }

  // For IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = 'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.';
    }
  }


  canDeactivate(): boolean {
    return !this.form.dirty;
  }


  ngOnInit() {
    this.event = this._eventsService.getSelectedEvent();

    this.sessionsWithPreferences = this.event.sessions ? this.event.sessions.filter(s => s.options.length > 1) : [];
    this.hasEventInfo = (this.event.questions && this.event.questions.length > 0)
      || (this.sessionsWithPreferences.length > 0)
      || (this.event.options && this.event.options.length > 0)
      || (this.event.settings &&
        (this.event.settings.studentEvent || this.event.settings.hasPromoCodes || this.event.settings.membershipEnabled));

    this._registrationService.updateRegistration();
    this._registrationSubscription = this._registrationService.registration$
      .subscribe(r => this.registration = r);

    // TODO:  Centralize the getTitles to event Resolver?
    this.titles = this._registrationService.getTitles();
    this.mealPreferences = this._registrationService.getMealPreferences(this.event.id);
    this.form = this._registrationFormBuilder.build(this.event);
    this.initCopyResidenceAddressChangeWatch();

    this._eventsService.getEventPricing(this.event.id).subscribe(prices => {
      const groupedPrices: PriceGroup[] = [];
      let currentGroupName = '';
      let currentPriceGroup: PriceGroup;

      prices.sort((a, b) => (a.listingGroupSequence > b.listingGroupSequence)
        ? 1
        : ((b.listingGroupSequence > a.listingGroupSequence) ? -1 : 0));

      prices.forEach(price => {
        const description = this.getPriceDescription(price);
        price.description = description;
        const groupName = price.listingGroupName;

        if (groupName !== currentGroupName) {
          currentPriceGroup = new PriceGroup();
          currentPriceGroup.description = groupName;
          currentPriceGroup.unitDescription = price.listingGroupUnitDescription;
          currentGroupName = groupName;
          currentPriceGroup.priceId = description;
          groupedPrices.push(currentPriceGroup);
        }

        currentPriceGroup.prices.push(price);
      });
      this.priceGroups = groupedPrices;
    });

    window.scrollTo(0, 0);
  }


  getPriceDescription(price: IPrice): string {
    if (price.startTime == null && price.endTime == null) {
      return price.description;
    }

    if (price.endTime == null) {
      return 'Starting ' + this.datePipe.transform(price.startTime, 'MMMM d, yyyy');
    }

    if (price.startTime == null) {
      return 'Until ' + this.datePipe.transform(price.endTime, 'MMMM d, yyyy');
    }

    return this.datePipe.transform(price.startTime, 'MMM d, yyyy') + ' - ' + this.datePipe.transform(price.endTime, 'MMM d, yyyy');
  }


  initCopyResidenceAddressChangeWatch() {
    this._mailingAddressSameAsResidenceAddress = this.form.get('mailing.useResidenceAddress')
      .valueChanges
      .subscribe(useResidenceAddress => {
        const residenceAddressControls = <UntypedFormGroup>this.form.get('residence');
        const mailingAddressControls = <UntypedFormGroup>this.form.get('mailing');
        const mailingAddressModel = this._registrationFormBuilder.getMailingAddressFormModel();

        if (useResidenceAddress) {
          mailingAddressControls.markAsUntouched();
          mailingAddressControls.markAsPristine();

          Object.keys(mailingAddressControls.controls).forEach(key => {
            if (key !== 'useResidenceAddress' && key !== 'type') {
              mailingAddressControls.controls[key].clearValidators();
              mailingAddressControls.controls[key]
                .setValue((key === 'country') ? <string>residenceAddressControls.controls[key].value : '');
              mailingAddressControls.controls[key].updateValueAndValidity();
              mailingAddressControls.controls[key].disable();
            }
          });
        } else {
          Object.keys(mailingAddressControls.controls).forEach(key => {

            if (key !== 'useResidenceAddress' && key !== 'type') {
              mailingAddressControls.controls[key].enable();
              mailingAddressControls.controls[key]
                .setValue((key === 'country') ? <string>residenceAddressControls.controls[key].value : mailingAddressModel[key][0]);
              mailingAddressControls.controls[key].setValidators(mailingAddressModel[key][1]);
              mailingAddressControls.controls[key].updateValueAndValidity();
            }
          });
        }
      });

    // Default to TRUE
    this.form.get('mailing.useResidenceAddress').setValue(true);
    this.form.get('mailing.useResidenceAddress').updateValueAndValidity();
  }


  ngOnDestroy() {
    this._registrationSubscription.unsubscribe();
    this._mailingAddressSameAsResidenceAddress.unsubscribe();
  }


  resetForm() {
    this.form.reset({
      residence: {country: this.form.get('residence.country').value},
      mailing: {useResidenceAddress: true},
    });

    this.formTitle = 'New Attendee';
    this.mainForm.resetForm();
    this.form.get('mealPreference').setValue('0');

    this.form.setControl(
      'sessionPreferences',
      this._fb.array(this._registrationFormBuilder.buildSessionPreferencesForm(this.sessionsWithPreferences))
    );

    this.form.setControl(
      'answers',
      this._fb.array(this._registrationFormBuilder.buildQuestionsForm(this.event.questions))
    );

    this.form.setControl(
      'options',
      this._fb.array(this._registrationFormBuilder.buildOptionsForm(this.event.options))
    );

    this.form.get('phone.home').setValue('');
    this.form.get('phone.mobile').setValue('');
    this.form.get('mailing.useResidenceAddress').setValue(true);
    this.form.get('mailing.useResidenceAddress').updateValueAndValidity();
    this.uxpQuestionList?.loadAnswers([]);
    this.form.updateValueAndValidity();
  }


  getSelectedOptions(): Array<IOption> {
    const optionFormValue = this.form.get('options').value;

    if (!optionFormValue) return [];

    const selectedOptionIds: Array<string> = optionFormValue
      .filter(x => x.isSelected)
      .map(optionForm => optionForm.id);

    return this.event.options.filter(x => selectedOptionIds.includes(x.id));
  };


  onEditAttendee(referenceNumber: string) {
    this.resetForm();

    this._registrationService
      .getAttendee(referenceNumber)
      .subscribe(r => {
        this.formTitle = 'Edit Attendee';
        this.form.patchValue(r);
        this.form.get('phone').patchValue({mobile: r.mobile, home: r.home});
        this.form.get('options').patchValue(r.options.map(x => <IOption>{id: x, isSelected: true}));
        this.uxpQuestionList?.loadAnswers(r.answers);
      });
  }


  onDeleteAttendee(referenceNumber: string) {
    this._confirmationService.confirm({
      message: 'Are you sure you want to delete this attendee?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteAttendee(referenceNumber);
      },
      reject: () => {}
    });
  }


  deleteAttendee(referenceNumber: string) {
    this._registrationService.removeAttendee(referenceNumber);

    if (referenceNumber === this.form.get('referenceNumber').value) {
      this.resetForm();
    }
  }


  addAttendee() {
    if (!this.form.valid) return;
    this.processing = true;

    const formValue = this.form.value;
    const addAttendee = new Attendee();
    Object.assign(addAttendee, formValue);
    addAttendee.addressesAreTheSame = false;

    if (formValue.mailing.useResidenceAddress) {
      Object.assign(addAttendee.mailing, <ICopyAddress>formValue.residence);
      addAttendee.addressesAreTheSame = true;
    }

    const selectedOptions = (<Array<IOption>>formValue.options).filter(x => x.isSelected).map(x => x.id);
    addAttendee.options = selectedOptions;
    addAttendee.home = formValue.phone.home;
    addAttendee.mobile = formValue.phone.mobile;

    this._registrationService
      .saveAttendee(addAttendee)
      .subscribe(r => {
        this.resetForm();
        this.processing = false;
        window.scrollTo(0, 0);
      }, err => {
        this.processing = false;
        window.scrollTo(0, 0);
        throw err;
      });
  }
}
