import {map} from 'rxjs/operators';
import { StorageService } from '../../shared/core/storage/storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../registration.service';
import { EventsService } from '../../events/events.service';
import { IEvent } from 'app/shared/models/event';
import { IRegistration } from 'app/shared/models/registration';
import {Subscription} from 'rxjs/internal/Subscription';



@Component({
  selector: 'app-registration-payment-thankyou',
  templateUrl: './registration-payment-thankyou.component.html',
  styleUrls: ['./registration-payment-thankyou.scss']
}) export class RegistrationThankYouComponent implements OnInit {
  event: IEvent;
  registration: IRegistration;
  hasCustomTemplate: boolean = false;
  private _registrationSubscription: Subscription;


  constructor(
    private _route: ActivatedRoute,
    private _storageService: StorageService,
    private _registrationService: RegistrationService,
    private _eventsService: EventsService,
    private _router: Router
  ) {}


  ngOnInit(): void {
    this.event = this._eventsService.getSelectedEvent();
    this._registrationService.updateRegistration();

    this._registrationSubscription = this._registrationService.registration$.pipe(map(r => r)).subscribe(r => {
      if (r == null) return;

      this.registration = r;
      this.hasCustomTemplate = r.confirmationHtmlTemplate != null;
      this._registrationService.clearRegistration();
      this._storageService.clear();
    });

    if (!this.registration) this._router.navigateByUrl(`/events/${this.event.id}`);
  }


  printReceipt(pritableElementId: string) {
      let printContents, popupWin;
      printContents = document.getElementById(pritableElementId).innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <!DOCTYPE html>
        <html>
            <head>
                <meta charset="utf-8" />
                <title>Registration Receipt</title>
                <style>
                    html { font: 11pt sans-serif; }
                    @media print {
                        .notprintable { display: none; }
                        .col-print-1 {width:'8%';  float:left;}
                        .col-print-2 {width:'16%'; float:left;}
                        .col-print-3 {width:'25%'; float:left;}
                        .col-print-4 {width:'33%'; float:left; font-weight:bold;}
                        .col-print-5 {width:'42%'; float:left;}
                        .col-print-6 {width:'50%'; float:left;}
                        .col-print-7 {width:'58%'; float:left;}
                        .col-print-8 {width:'66%'; float:left;}
                        .col-print-9 {width:'75%'; float:left;}
                        .col-print-10 {width:'83%'; float:left;}
                        .col-print-11 {width:'92%'; float:left;}
                        .col-print-12 {width:'100%'; float:left;}
                        .col-md-offset-1 { margin-left: 8.33333333%; }
                    }
                </style>
                <link type="text/css" href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet" media="print" />
            </head>
            <body onload="window.print();window.close();"><div class='container'>${printContents}</div></body>
        </html>
      `);
      popupWin.document.close();
  }
}
