<event-banner></event-banner>




<main role="main">
  <div class="container">

    <ng-container *ngIf="hasCustomTemplate">
      <div [innerHTML]="registration.confirmationHtmlTemplate" id="printableSection"></div>
      <ng-container *ngTemplateOutlet="printButtons"></ng-container>
    </ng-container>


    <ng-container *ngIf="!hasCustomTemplate">
      <div class="row justify-content-md-center" id="printableSection">
        <div class="col-12 col-md-9 col-lg-6">

          <div class="card mt-4">
            <div class="card-header">Confirmation</div>
            <div class="card-body">
              <h3 class="card-title text-center">Thank you!</h3>
              <p class="card-text text-center">Your registration has been successfully processed!</p>

              <table class="table table-bordered mt-4 mb-4">
                <thead>
                  <tr>
                    <th>Attendee</th>
                    <th>Reference Number</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let attendee of registration?.attendees">
                    <td>{{attendee.firstName}} {{attendee.lastName}}</td>
                    <td>{{attendee.referenceNumber}}</td>
                    <td>{{attendee.total | currency:'USD':true}}</td>
                  </tr>
                </tbody>
              </table>

              <dl class="mt-4 mb-4">
                <dt>Event:</dt>
                <dd>{{event?.name}}</dd>
                <dd>{{event?.startTime | date}}</dd>

                <dt>Registration Reference Number:</dt>
                <dd class="font-monospace">{{registration?.referenceNumber}}</dd>

                <dt>Registration Total:</dt>
                <dd>{{registration?.total | currency:'USD':true}}</dd>

                <dt>Cancellation Policy:</dt>
                <dd [innerHtml]="event?.cancellationPolicy"></dd>
              </dl>

              <ng-container *ngTemplateOutlet="printButtons"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</main>





<ng-template #printButtons>
  <div class="col-12 notprintable">
    <a
      *ngIf="event?.exitPage"
      class="btn btn-primary ms-2 float-end"
      href="{{event.exitPage.url}}">
      {{event.exitPage.text ?? 'Next Steps'}}
    </a>

    <button
      class="btn btn-secondary ms-2 float-end"
      type="button"
      (click)="printReceipt('printableSection')">
      Print Receipt
    </button>

    <a
      *ngIf="!event.exitPage"
      class="btn btn-secondary ms-2 float-end"
      [routerLink]="['/']">
      More Events
    </a>
  </div>
</ng-template>
