<event-banner></event-banner>




<h4 class="print-view-only" style="text-align:center; width: 100%;">
  <hr />
  Registration has not been processed yet and printing this page is not allowed
  <hr />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
</h4>






<div class="not-printable">
  <main role="main">
    <form [formGroup]="form" class="payment-form" novalidate (ngSubmit)="submitPayment();" #mainForm="ngForm">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="d-lg-none">
              <ng-container *ngTemplateOutlet="attendeesCard"></ng-container>
            </div>

            <div *ngIf="isPaymentEnabled" class="card mt-4">
              <div class="card-header">Payment Details</div>
              <div class="card-body">
                <p class="card-text">
                  <strong>Registration Reference Number: </strong>
                  <span class="font-monospace">{{ registration?.referenceNumber }}</span>
                </p>

                <p class="card-text"><strong>Please Note:</strong> This registration will not be accepted until the payment information has been added and the registration has been successfully processed.</p>

                <fieldset class="mt-4">
                  <legend>Credit Card Information</legend>

                  <div class="row g-2">

                    <div class="col-12 col-md-6">
                      <label class="form-label" for="firstNameOnCard">First Name on Card*</label>
                      <input type="text" formControlName="firstNameOnCard" id="firstNameOnCard" class="form-control" required>
                    </div>

                    <div class="col-12 col-md-6">
                      <label class="form-label" for="lastNameOnCard">Last Name on Card*</label>
                      <input type="text" formControlName="lastNameOnCard" id="lastNameOnCard" class="form-control" required>
                    </div>

                    <div class="col-12">
                      <label class="form-label">Card Number*</label>
                      <p-inputMask type="text" slotChar=" " [autoClear]="false" formControlName="number" mask="999999999999999?9" styleClass="form-control"></p-inputMask>
                      <span class="help-block" *ngIf="hasError('invalidCreditCard', 'number')">Credit Card number is invalid.</span>
                    </div>

                    <div class="col-12 col-md-4">
                      <label class="form-label" for="expirationMonth">Month:</label>
                      <select class="form-select" formControlName="expirationMonth" id="expirationMonth" required>
                        <option value="" selected disabled>-</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>

                    <div class="col-12 col-md-4">
                      <label class="form-label" for="expirationYear">Year:</label>
                      <select class="form-select" formControlName="expirationYear" id="expirationYear" required>
                        <option selected disabled value="">-</option>
                        <option *ngFor="let year of years">{{ year }}</option>
                      </select>
                      <span class="help-block" *ngIf="!form.get('expirationDate').valid">Expiration date is in the past.</span>
                    </div>

                    <div class="col-12 col-md-4" [ngClass]="{'has-error':hasErrors('verificationCode')}">
                      <label class="form-label">
                        <i class="fa fa-info-circle" ngbTooltip="The Credit Card Verification Code for Via, Master Card, and Discover is a 3-digit number printed on the back of your card. For American Express, it is a 4-digit number printed on the front of your card."></i>
                        CVV/CVC*
                      </label>
                      <input type="number" maxlength="4" minlength="3" formControlName="verificationCode" class="form-control" required>
                    </div>

                    <div class="col-12" >
                      <label>Email*</label>
                      <input type="text" formControlName="payerEmail" class="form-control" required [ngClass]="{'border-danger': form.value.payerEmail !== '' && !!form.get('payerEmail').errors}">
                    </div>
                  </div>
                </fieldset>

                <fieldset class="mt-4">
                  <legend>Billing Address</legend>
                  <form-address [addressForm]="form.get('billingAddress')" [mainForm]="mainForm"></form-address>
                </fieldset>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="d-none d-lg-block">
              <ng-container *ngTemplateOutlet="attendeesCard"></ng-container>
            </div>

            <div class="card mt-4">
              <div class="card-header">Terms and Conditions</div>
              <div class="card-body">
                <p class="card-text" [innerHtml]="event?.cancellationPolicy"></p>

                <p class="card-text" *ngIf="isPaymentEnabled">By checking the checkbox and clicking 'Process Payment' you are indicating that you have read, understand, and agree to the terms and conditions listed above and authorize CUFI to charge your credit card a total of <strong>{{registration?.total | currency:'USD':true}}</strong>.</p>

                <p class="card-text" *ngIf="!isPaymentEnabled">By checking the checkbox and clicking 'Process Payment' you are indicating that you have read, understand, and agree to the terms and conditions listed above.</p>

                <p class="card-text">You are also indicating that all attendees agree to receive emails from CUFI.</p>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="agreed" id="agreed">
                  <label class="form-check-label" for="agreed">I Agree</label>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <button type="submit" class="btn btn-primary ms-2 float-end" [disabled]="form.invalid || processing">
                <ng-container *ngIf="!processing">Process Payment</ng-container>
                <ng-container *ngIf="processing">
                  Processing
                  <i class="fa fa-spinner fa-spin"></i>
                </ng-container>
              </button>

              <a [routerLink]="['/events', event.id]" class="btn btn-secondary ms-2 float-end">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </main>
</div>






<ng-template #attendeesCard>
  <div class="card mt-4">
    <div class="card-header">Attendees</div>
    <div class="card-body">
      <p class="card-text" *ngIf="!registration?.attendees || registration?.attendees.length === 0">Please add an attendee to continue</p>

      <table *ngIf="registration?.attendees && registration?.attendees.length > 0" class="table">
        <tbody>
          <tr *ngFor="let attendee of registration?.attendees">
            <td>{{attendee.firstName}} {{attendee.lastName}}</td>
            <td class="text-end">{{attendee.total | currency:'USD':true}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td><strong>Total:</strong></td>
            <td class="text-end"><strong>{{registration.total | currency:'USD':true}}</strong></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</ng-template>
