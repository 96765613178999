import { NgModule, Optional, SkipSelf } from '@angular/core';

// Pipes
import { MomentPipe } from './pipes/moment.pipe';
import { InitCapsPipe } from './pipes/init-caps.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

// Directives
import { CreditCardDirective } from './directives/credit-card.directive';

// Extensions
import './extensions/string.extensions';

// Includes
import './includes/rxjs-operators';
import {MessagesModule} from 'primeng/messages';




@NgModule({
    imports: [MessagesModule],
    exports: [
        MomentPipe,
        InitCapsPipe,
        CreditCardDirective,
        FilterPipe,
        TruncatePipe
    ],
    declarations: [
        MomentPipe,
        InitCapsPipe,
        CreditCardDirective,
        FilterPipe,
        TruncatePipe
    ]
})

export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) { }
}
