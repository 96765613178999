import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EventsRoutingModule, EventsRoutedComponents } from './events-routing.module';
import { EventsService } from './events.service';
import { EventResolver } from './event.resolver';



@NgModule({
  imports: [
    SharedModule,
    EventsRoutingModule
  ],
  declarations: [
    EventsRoutedComponents
  ],
  providers: [
    EventResolver,
    EventsService
  ]
})
export class EventsModule {}
