import {map} from 'rxjs/operators';
import {FormBaseClass} from 'app/shared/FormBaseClass';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ComponentCanDeactivate} from '../../shared/core/guards/app.pending-changes-guard';
import {EventsService} from '../../events/events.service';
import {RegistrationService} from '../registration.service';
import {IEvent} from 'app/shared/models/event';
import {IRegistration} from 'app/shared/models/registration';
import {IPayment} from 'app/shared/models/payment';
import {Subscription} from 'rxjs/internal/Subscription';



@Component({
  selector: 'app-registration-payment',
  templateUrl: './registration-payment.component.html'
})
export class RegistrationPaymentComponent extends FormBaseClass implements OnInit, OnDestroy, ComponentCanDeactivate {
  event: IEvent;
  registration: IRegistration;
  private _registrationSubscription: Subscription;
  years = Array(10).fill(new Date().getFullYear()).map((e, i) => e += i);
  expirationYearSubscription: Subscription;
  expirationMonthSubscription: Subscription;
  public isPaymentEnabled = true;
  processing = false;


  // TODO:  Need to move to validator classes
  static expirationDateValidator(control) {
    if (control.value == null || control.value === '') return null;

    const date = Date.parse(control.value);
    if (date < Date.now()) return {'invalidExpiration': true};

    return null;
  }


  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value == null || control.value === '') {
      return null;
    }

    const ccNumber = control.value.replace(/\D/g, '');

    if (ccNumber.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return {'invalidCreditCard': true};
    }
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _eventsService: EventsService,
    private _registrationService: RegistrationService
  ) {
    super();
  }


  canDeactivate(): boolean {
    return !this.form.dirty;
  }


  ngOnInit(): void {
    this.form = this._fb.group({
      number: ['', [Validators.required, RegistrationPaymentComponent.creditCardValidator]],
      expirationMonth: ['', Validators.required],
      expirationYear: ['', Validators.required],
      expirationDate: ['', RegistrationPaymentComponent.expirationDateValidator],
      verificationCode: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      firstNameOnCard: ['', Validators.required],
      lastNameOnCard: ['', Validators.required],
      payerEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$')]],
      agreed: ['', Validators.required],
      billingAddress: this._fb.group({
        address1: ['', Validators.required],
        address2: '',
        city: ['', Validators.required],
        state: ['', Validators.required],
        postalCode: ['', Validators.required],
        country: ['US', Validators.required]
      }),
    });

    this.event = this._eventsService.getSelectedEvent();
    this._registrationService.updateRegistration();
    this.isPaymentEnabled = true;

    this._registrationSubscription = this._registrationService.registration$.pipe(map(r => r)).subscribe(r => {
      if (r == null) return;

      this.registration = r;
      if (r.total === 0) {
        this.isPaymentEnabled = false;

        this.form = this._fb.group({
          agreed: ['', Validators.required]
        });

        const firstAttendee = this.registration.attendees[0];

        this._registrationService
          .getAttendee(firstAttendee.referenceNumber)
          .subscribe(x => {
            this.registration.attendees[0] = x;
          });
      }
    });

    // TODO:  If registration is empty throw error and/or redirect back to registration

    window.scrollTo(0, 0);
  }


  ngOnDestroy(): void {
    this._registrationSubscription.unsubscribe();
  }


  // TODO: Need to remove value.. get it from form
  submitPayment() {
    this.processing = true;
    const payment = <IPayment>{};
    const paymentForm = this.form.value;

    Object.assign(payment, paymentForm);
    if (!this.isPaymentEnabled) {
      const firstAttendee = this.registration.attendees[0];
      Object.assign(payment, {
        firstNameOnCard: firstAttendee.firstName,
        lastNameOnCard: firstAttendee.lastName,
        payerEmail: '',
        billingAddress: firstAttendee.residence
      });
    }

    payment.referenceNumber = this.registration.referenceNumber;
    payment.amount = this.registration.total;

    this._registrationService
      .completeRegistration(payment)
      .subscribe((r) => {
        this.form.markAsPristine();
        this._router.navigateByUrl(`/events/${this.event.id}/thankyou`);
      }, err => {
        this.processing = false;
        window.scrollTo(0, 0);
        throw err;
      });
  }
}
