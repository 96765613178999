<event-banner></event-banner>




<main role="main">
  <div class="container">
    <p-confirmDialog width="425"></p-confirmDialog>
    <form [formGroup]="form" novalidate (ngSubmit)="addAttendee();" #mainForm="ngForm" class="row">

      <div class="col-lg-8">
        <input type="hidden" formControlName="referenceNumber">

        <div class="d-lg-none">
          <ng-container *ngTemplateOutlet="detailsCard"></ng-container>
        </div>

        <div class="card mt-4">
          <div class="card-header">{{formTitle}}</div>
          <div class="card-body">
            <fieldset class="row g-2">
              <div class="col-12 col-md-3">
                <label class="form-label">Title</label>
                <select class="form-select" formControlName="title">
                  <option value="">Select</option>
                  <option *ngFor="let title of titles | async" [value]="title">{{title}}</option>
                </select>
              </div>

              <div class="col-12 col-md-4">
                <label class="form-label">First Name*</label>
                <input type="text" formControlName="firstName" class="form-control">
              </div>

              <div class="col-12 col-md-5">
                <label class="form-label">Last Name*</label>
                <input type="text" formControlName="lastName" class="form-control">
              </div>

              <div class="col-12 col-md-6">
                <label class="form-label">Email*</label>
                <input type="email" formControlName="email" class="form-control" [ngClass]="{'border-danger': !!form.value.email && !!form.get('email').errors}">
                <small class="form-label">
                  <em>Attendees agree to receive emails from CUFI</em>
                </small>
              </div>

              <ng-container formGroupName="phone">
                <div class="col-12 col-md-3" [class.has-error]="hasError('bothRequired', 'phone', mainForm)">
                  <label class="form-label">
                    <i class="fa fa-info-circle" ngbTooltip="Only used to text information regarding event and schedule."></i>
                    Mobile<span *ngIf="!form.value.phone.home">*</span>
                  </label>
                  <input type="tel" formControlName="mobile" mask="(999) 999-9999" class="form-control">
                </div>

                <div class="col-12 col-md-3" [class.has-error]="hasError('bothRequired', 'phone', mainForm)">
                  <label class="form-label">
                    Home Phone<span *ngIf="!form.value.phone.mobile">*</span>
                  </label>
                  <input type="tel" formControlName="home" mask="(999) 999-9999" class="form-control">
                </div>
              </ng-container>

              <div class="col-12">
                <label for="example-text-input" class="form-label">
                  <i class="fa fa-info-circle" ngbTooltip="May incur additional fee."></i>
                  Meal Preference
                </label>

                <select class="form-select" formControlName="mealPreference">
                  <option *ngFor="let preference of mealPreferences | async" value="{{preference.id}}">
                    {{preference.friendlyName}}
                  </option>
                </select>
              </div>
            </fieldset>

            <fieldset [formGroup]="form.controls['residence']" class="mt-4">
              <legend>
                <i class="fa fa-info-circle" ngbTooltip="PO Box is not allowed."></i>
                Residence Address
              </legend>

              <form-address [addressForm]="form.controls['residence']" [mainForm]="mainForm"></form-address>
            </fieldset>

            <fieldset [formGroup]="form.controls['mailing']" class="mt-4">
              <legend>Mailing Address</legend>

              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="useResidenceAddress" id="useResidenceAddress">
                <label class="form-check-label" for="useResidenceAddress">
                  Use Residence Address
                </label>
              </div>

              <form-address *ngIf="!form.controls['mailing'].value.useResidenceAddress" [addressForm]="form.controls['mailing']" [mainForm]="mainForm"></form-address>
            </fieldset>

            <fieldset *ngIf="event.questions && event.questions.length > 0" class="mt-4">
              <legend>Questions*</legend>

              <app-registration-questions
                #uxpQuestionList
                [questions]="event.questions"
                [questionsForm]="form.get('answers')"
                [mainForm]="mainForm">
              </app-registration-questions>
            </fieldset>

            <fieldset *ngIf="event.sessions && event.sessions.length > 0" class="mt-4">
              <legend>Session Preferences</legend>

              <app-session-preferences
                [sessions]="event.sessions"
                [sessionPreferencesForm]="form.get('sessionPreferences')">
              </app-session-preferences>
            </fieldset>

            <fieldset *ngIf="event.options && event.options.length > 0" class="mt-4">
              <legend>Add-On Options</legend>

              <app-registration-options
                [options]="event.options"
                [optionsForm]="form.get('options')">
              </app-registration-options>
            </fieldset>

            <fieldset class="row g-2 mt-4">
              <div class="col-12">
                <label class="form-label" for="birthDate">Birth Date*</label>
                <input type="date" formControlName="birthDate" class="form-control" id="birthDate">
                <label for="example-text-input" class="form-label">
                  <i class="fa fa-info-circle" ngbTooltip="Required for Camp CUFI attendees"></i>
                  Required for Camp CUFI attendees
                </label>
              </div>
            </fieldset>

            <fieldset class="row g-2 mt-4" *ngIf="event.settings && (event.settings?.studentEvent || event.settings?.hasPromoCodes || event.settings?.membershipEnabled)">
              <legend>Additional Info</legend>

              <div class="col-12" *ngIf="event.settings && event.settings?.studentEvent">
                <label class="form-label" for="schoolName">School Name*</label>
                <input type="text" formControlName="schoolName" class="form-control" id="schoolName">
              </div>

              <div class="col-12" *ngIf="event.settings && event.settings?.membershipEnabled" [ngClass]="{'has-error':hasErrors('memberId', mainForm), 'has-success':form.get('memberId').touched && !hasErrors('memberId', mainForm)}">
                <label class="form-label" for="memberId">Membership Card Number</label>
                <input type="text" formControlName="memberId" class="form-control" id="memberId">

                <span class="help-block" *ngIf="hasError('isInvalid','memberId', mainForm)">
                  {{form.get('memberId').errors.message}}
                </span>

                <span class="text-success" *ngIf="form.get('memberId').touched && form.get('memberId').value.length > 0 && !hasError('isInvalid','memberId', mainForm)">
                  Membership card number is valid <i class="fa fa-check " aria-hidden="true"></i>
                </span>
              </div>

              <div *ngIf="event.settings && event.settings?.hasPromoCodes" class="col-12">
                <label class="form-label" for="promoCode">
                  Promotional Code
                  <i *ngIf="!form.get('promoCode').valid && !form.get('promoCode').invalid" class="fa fa-spinner fa-spin"></i>
                </label>
                <input type="text" formControlName="promoCode" id="promoCode" class="form-control">

                <ng-container *ngIf="form.get('promoCode').value">
                  <span class="text-danger" *ngIf="form.get('promoCode').invalid">
                    Promotional Code is invalid <i class="fa fa-times" aria-hidden="true"></i>
                  </span>

                  <span class="text-success" *ngIf="form.get('promoCode').valid">
                    Promotional Code is valid <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                </ng-container>
              </div>
            </fieldset>
          </div>
        </div>

        <!-- Save -->
        <div class="col-12 mt-4">
          <button type="submit" class="btn btn-primary ms-2 float-end" [disabled]="!form.valid || processing">
            <ng-container *ngIf="!processing">Save Attendee</ng-container>
            <ng-container *ngIf="processing">
              Saving <i class="fa fa-spinner fa-spin"></i>
            </ng-container>
          </button>
          <button class="btn btn-secondary ms-2 float-end" [disabled]="form.pristine" type="button" (click)="resetForm()">Reset Form</button>
        </div>
      </div>


      <div class="col-lg-4">
        <div class="d-none d-lg-block">
          <ng-container *ngTemplateOutlet="detailsCard"></ng-container>
        </div>


        <div class="card mt-4">
          <div class="card-header">Attendees</div>
          <div class="card-body">
            <p class="card-text" *ngIf="!registration?.attendees || registration?.attendees.length === 0">
              <em>(None Added)</em>
            </p>

            <table *ngIf="registration?.attendees && registration?.attendees.length > 0" class="table">
              <tbody>
                <tr *ngFor="let attendee of registration?.attendees" [ngClass]="{'bg-white': attendee.referenceNumber === form.get('referenceNumber').value}">
                  <td>{{attendee.firstName}} {{attendee.lastName}}</td>

                  <td>{{attendee.total | currency:'USD':true}}</td>

                  <td class="text-end">
                    <a href="javascript:void(0)" (click)="onEditAttendee(attendee.referenceNumber)">
                      <i class="fa fa-fw fa-pencil"></i>
                    </a>
                    <a href="javascript:void(0)" (click)="onDeleteAttendee(attendee.referenceNumber)">
                      <i class="fa fa-fw fa-times"></i>
                    </a>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td><strong>Total:</strong></td>
                  <td><strong>{{registration.total | currency:'USD':true}}</strong></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <button
          class="btn btn-primary float-end mt-4"
          type="button"
          [disabled]="form.dirty || !registration?.attendees || registration?.attendees.length === 0"
          [routerLink]="['payment']">
            Checkout
        </button>
      </div>

    </form>
  </div>
</main>



<ng-template #detailsCard>
  <div class="card mt-4">
    <div class="card-header">
      Details <span *ngIf="priceGroups != null && priceGroups.length > 0">&amp; Pricing</span>
    </div>

    <div class="card-body">
      <p class="card-text">{{event?.summary}}</p>

      <ng-container *ngIf="priceGroups != null && priceGroups.length > 0">
        <table class="table table-sm" *ngFor="let priceGroup of priceGroups">
          <thead>
            <tr>
              <th colspan="2">{{priceGroup.description}} {{priceGroup.unitDescription}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let price of priceGroup.prices">
              <td>{{price.description}}</td>
              <td class="text-end">{{ price.amount ? (price.amount | currency: 'USD': true) : '-' }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</ng-template>
