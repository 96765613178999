import { Component }   from '@angular/core';
import { EventsService } from './events.service';



@Component({
  template: `<router-outlet></router-outlet>`,
  providers: [ EventsService ]
})
export class EventsComponent {
  constructor(_eventsService: EventsService) {}
}
