<div [formGroup]="addressForm" class="row g-2">
  <div class="col-12">
    <label class="form-label" for="address1-{{componentId}}">Address 1*</label>
    <input type="text" class="form-control" formControlName="address1" id="address1-{{componentId}}" required>
    <span class="help-block" *ngIf="addressForm.get('address1').hasError('pattern') && mainForm.submitted">
      Enter a valid address
    </span>
  </div>

  <div class="col-12">
    <label class="form-label" for="address2-{{componentId}}">Address 2</label>
    <input type="text" class="form-control" formControlName="address2" id="address2-{{componentId}}">
  </div>

  <div class="col-12 col-md-4">
    <label class="form-label" for="city-{{componentId}}">City*</label>
    <input type="text" class="form-control" formControlName="city" id="city-{{componentId}}" required>
  </div>

  <div class="col-12 col-md-4">
    <label class="form-label" for="state-{{componentId}}">State/Region*</label>
    <select class="form-select" name="state" formControlName="state" id="state-{{componentId}}">
      <option value="">Select State/Region</option>
      <option *ngFor="let s of states | async" [value]="s.code">{{s.name}}</option>
    </select>
  </div>

  <div class="col-12 col-md-4">
    <label class="form-label" for="postalCode-{{componentId}}">Postal Code*</label>
    <input type="text" class="form-control" formControlName="postalCode" id="postalCode-{{componentId}}">
  </div>

  <div class="col-12">
    <label class="form-label" for="country-{{componentId}}">Country*</label>
    <select class="form-select" (change)="countryChange($event.target.value)" formControlName="country" id="country-{{componentId}}">
      <option *ngFor="let country of countries | async" [value]="country.code">{{country.name}}</option>
    </select>
  </div>
</div>
