import { Component, Input } from '@angular/core';
import { UntypedFormArray, NgForm } from '@angular/forms';
import { IQuestion } from 'app/shared/models/question';
import { IAnswer } from 'app/shared/models/attendee';



@Component({
  selector: 'app-registration-questions',
  templateUrl: './registration-questions-list.component.html'
}) export class QuestionsListComponent {
    @Input('questions') public questions: Array<IQuestion>;
    @Input('questionsForm') questionsForm: UntypedFormArray;
    @Input() mainForm:NgForm;


    answers: IAnswer[];


    loadAnswers(answers: IAnswer[]) {
      this.answers = answers;
    }
}
