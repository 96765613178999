<div class="hero">
  <div class="hero-content" *ngIf="bannerText">
    <span>{{getFormattedDateRange()}}</span>
    <h2>{{bannerText}}</h2>
    <span>{{selectedEvent.location}}</span>
  </div>

  <div class="hero-content" *ngIf="!bannerText">
    <h2>Upcoming Events</h2>
  </div>
</div>
