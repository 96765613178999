import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration.component';
import { AddressComponent } from './address-component/address.component';
import { Accordion, AccordionGroup } from '../shared/core/directives/single-item-visible-accordion.component';
import { RegistrationQuestionComponent } from './questions-component/registration-question.component';
import { QuestionsListComponent } from './questions-component/registration-questions-list.component';
import { RegistrationOptionsListComponent } from './options-component/registration-options-list.component';
import { RegistrationOptionComponent } from './options-component/registration-option.component';
import { RegistrationPaymentComponent } from './payment/registration-payment.component';
import { RegistrationThankYouComponent } from './payment/registration-payment-thankyou.component';
import { PendingChangesGuard } from '../shared/core/guards/app.pending-changes-guard';
import { SessionsListComponent } from 'app/registration/session-preferences-component/session-preference-list.component';
import { SessionPreferenceComponent } from 'app/registration/session-preferences-component/session-preference.component';
import { PopoverModule } from '../shared/lib/ngx-popover';



const routes: Routes = [{
  path: '',
  children: [
    { path: '', component: RegistrationComponent, canDeactivate: [PendingChangesGuard] },
    { path: 'payment', component: RegistrationPaymentComponent, canDeactivate: [PendingChangesGuard] },
    { path: 'thankyou', component: RegistrationThankYouComponent }
  ]
}, ];



@NgModule({
  imports: [
    RouterModule.forChild(routes),
    PopoverModule
  ],
  exports: [RouterModule, PopoverModule],
  providers: [PendingChangesGuard]
}) export class RegistrationRoutingModule {}



export const RegistrationRoutedComponents = [
  RegistrationComponent,
  AddressComponent,
  RegistrationQuestionComponent,
  QuestionsListComponent,
  RegistrationOptionsListComponent,
  RegistrationOptionComponent,
  RegistrationPaymentComponent,
  RegistrationThankYouComponent,
  Accordion,
  AccordionGroup,
  SessionsListComponent,
  SessionPreferenceComponent,
  SessionsListComponent,
  SessionPreferenceComponent
];
