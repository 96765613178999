import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { environment } from '../../../../environments/environment';

import { NotificationsService } from '../notifications/notifications.service';


import { LoggingService } from '../logging/logging.service';
import * as StackTrace from 'stacktrace-js';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error) {

        const notificationsService = this.injector.get(NotificationsService);
        const loggingService = this.injector.get(LoggingService);
        const location = this.injector.get(LocationStrategy);
        const message = error.message ? error.message : error.toString();
        const url = location instanceof PathLocationStrategy
            ? location.path() : '';

        if (error.message && error.message.indexOf("Uncaught (in promise): Error: Cannot parse given Error object") >= 0){
            return;//TODO: We will ignore this error for now
        }

        const apiError = this.parseError(error);
        const unwrappedError = error.originalError || error;

        // Send Notification to user
        let errorsObject = error.error.errors;
        for (let property in errorsObject) {
          notificationsService.notify('error', 'Error', errorsObject[property][0]);
        }
    }

    private showApiErrorInConsole(apiError: ApiError): void {
        if (console && console.group && console.error) {
            console.group('Api Error Group');
            console.error(apiError);
            console.error(apiError.message);
            if (apiError.errors) {
                console.error(apiError.errors);
            }
            if (apiError.detail) {
                console.error(apiError.detail);
            }
            if (apiError.stack) {
                console.error(apiError.stack);
            }
            console.groupEnd();
        }
    }

    private showErrorInConsole(error: any): void {
        if (console && console.group && console.error) {
            console.group('Error Group');
            console.error(error);
            console.groupEnd();
        }
    }

    private parseError(error: any): ApiError {
        const unexpectedErrorMessage = 'Hmm.. Something went wrong. Try again shortly or call us at 210-477-4714';
        const statusCode = error.status ? error.status : 0;
        if (statusCode > 0) {

            // const apiError = <ApiError>(error.json() || {});
            // return apiError;
            return error;
        }
        return { message: unexpectedErrorMessage, isError: true };
    }


}
export interface ApiError {
    message: string;
    isError: boolean;

    errors?: ValidationError[];

    detail?: string;
    stack?: string;
}

export interface ValidationError {
    errorMessage: string;
    controlName: string;
    errorCode: string;
}
