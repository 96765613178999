import { LoggingService } from '../logging/logging.service';
import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';


type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable()
export class NotificationsService {

  notificationChange: Subject<Object> = new Subject<Object>();

  constructor(private _loggingService: LoggingService) { }

  notify(severity: Severities, summary: string, detail: string) {
    this.notificationChange.next({ severity, summary, detail, life: 5000 });
    this._loggingService.log('Info', detail);
  }
}
