import {mergeMap, map, tap} from 'rxjs/operators';
import {StorageService} from '../shared/core/storage/storage.service';
import {EventsService} from '../events/events.service';
import {Injectable, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {IEvent} from 'app/shared/models/event';
import {ILookup} from 'app/shared/models/lookup';
import {IRegistration} from 'app/shared/models/registration';
import {IPayment} from 'app/shared/models/payment';
import {Attendee} from 'app/shared/models/attendee';
import {Angulartics2} from 'angulartics2';
import {ITypeSafeEnum} from 'app/shared/models/typesafe.enum';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';



@Injectable()
export class RegistrationService implements OnInit {
  private _selectedEvent: IEvent;
  private _countries: Observable<ILookup[]>;
  private _registration = new BehaviorSubject<IRegistration>(null);
  registration$ = this._registration.asObservable();


  constructor(
    private http: HttpClient,
    private _storageService: StorageService,
    private _eventsService: EventsService,
    private angulartics2: Angulartics2
  ) {
    this._countries = this.http
      .get(environment.api.v2 + '/settings/countries')
      .pipe(map(res => <ILookup[]>res));
  }


  ngOnInit() {
    this.updateRegistration();
  }


  setEvent(event: IEvent) {
    this._selectedEvent = event;
  }


  updateRegistration() {
    const groupId = this._storageService.getItem('groupId');
    this._selectedEvent = this._eventsService.getSelectedEvent();

    if (groupId && groupId.length) {
      this.getRegistration(groupId).subscribe(registration => {
        if (registration == null) return;

        if (this._selectedEvent.code === registration.eventCode) {
          this.setCurrentRegistration(registration);

          this.angulartics2.eventTrack.next({
            action: 'updated',
            properties: {category: 'registration', label: groupId}
          });
        } else {
          // NOTE: groupId that is set does not match the current event
          this.clearRegistration();
        }
      });
    }
  }


  clearRegistration() {
    this._storageService.removeItem('groupId');
    this._registration.next(null);
  }


  completeRegistration(payment: IPayment) {
    return <Observable<IRegistration>>this.http.put(
        environment.api.v2 + '/registrations/{0}/complete'.formatWith(payment.referenceNumber), JSON.stringify({payment: payment})
      ).pipe(
        map(res => <IRegistration>res),
        tap(registration => {
          this.setCurrentRegistration(registration);
          this.angulartics2.eventTrack.next({
            action: 'completed',
            properties: {category: 'registration', label: registration.referenceNumber}
          });
        })
      );
  }


  checkMembership(eventId: string, memberId: string): Observable<any> {
    return <Observable<any>>this.http.post(
      environment.api.v2 + '/events/{0}/memberships'.formatWith(eventId), {memberId: memberId}
    ).pipe(map(res => <any>res));
  }


  private setCurrentRegistration(registration: IRegistration) {
    if (registration && registration.referenceNumber) {
      this._storageService.setItem('groupId', registration.referenceNumber);
      this._registration.next(registration);
    }
  }


  private getRegistration(groupId: string) {
    return this.http.get(
      environment.api.v2 + '/registrations/{0}'.formatWith(groupId)
    ).pipe(map(res => <IRegistration>res));
  }


  private createRegistration(eventCode: string): Observable<IRegistration> {
    this.angulartics2.eventTrack.next({
      action: 'created',
      properties: {
        category: 'registration',
        label: 'event:' + eventCode
      }
    });

    return this.http.post(
      environment.api.v2 + '/registrations', JSON.stringify({eventCode: eventCode})
    ).pipe(
      map(res => <IRegistration>res),
      tap(registration => {
        this.setCurrentRegistration(registration);
      })
    );
  }


  getAttendee(id: string) {
    return this.http.get(
      environment.api.v2 + '/attendees/{0}'.formatWith(id)
    ).pipe(map(res => <Attendee>res));
  }


  saveAttendee(attendee: Attendee): Observable<Attendee> {
    const registration = this._registration.getValue();
    this._selectedEvent = this._eventsService.getSelectedEvent();

    if (attendee.referenceNumber && attendee.referenceNumber !== '') {
      return this.updateAttendee(attendee);
    } else {
      if (registration && registration.referenceNumber) {
        return this.addAttendee(registration.referenceNumber, attendee);
      } else {
        return this.createRegistration(this._selectedEvent.code).pipe(
          mergeMap(r => this.addAttendee(r.referenceNumber, attendee)));
      }
    }
  }


  removeAttendee(id: string) {
    this.angulartics2.eventTrack.next({
      action: 'removed',
      properties: {category: 'registrant', label: id}
    });

    return this.http
      .delete(environment.api.v2 + '/attendees/{0}'.formatWith(id))
      .subscribe(res => this.updateRegistration());
  }


  private addAttendee(groupId: string, attendee: Attendee) {
    this.angulartics2.eventTrack.next({
      action: 'added',
      properties: {category: 'registrant', label: 'group:' + groupId}
    });

    return this.http.post(
      environment.api.v2 + '/registrations/{0}/attendees'.formatWith(groupId), JSON.stringify(attendee)
    ).pipe(
      map(res => {
        this.updateRegistration();
        return <Attendee>res;
      })
    );
  }


  private updateAttendee(attendee: Attendee) {
    this.angulartics2.eventTrack.next({
      action: 'updated',
      properties: {category: 'registrant', label: attendee.referenceNumber}
    });

    return this.http.put(
      environment.api.v2 + '/attendees/{0}'.formatWith(attendee.referenceNumber), JSON.stringify(attendee)
    ).pipe(
      map(res => {
        this.updateRegistration();
        return <Attendee>res;
      })
    );
  }


  getStatesByCountryCode(countryCode: string) {
    return <Observable<ILookup[]>>this.http.get(
      environment.api.v2 + `/settings/countries/${countryCode}/provinces`
    ).pipe(map(res => <ILookup[]>res));
  }


  getCountries() {
    return this._countries;
  }


  getTitles() {
    return <Observable<string[]>>this.http.get(
      environment.api.v2 + '/settings/titles'
    ).pipe(map(res => <string[]>res));
  }


  getMealPreferences(eventId: string) {
    return <Observable<ITypeSafeEnum[]>>this.http.get(
      environment.api.v2 + `/events/${eventId}/meal-preferences`
    ).pipe(
      map(res => {
        const mealPreferences = <ITypeSafeEnum[]>res;
        // return mealPreferences;
        return mealPreferences.filter(x => x.name !== 'kosher')
      })
    );
  }
}
