import { Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { IOption } from 'app/shared/models/event.option';



@Component({
  selector: 'app-registration-options',
  templateUrl: './registration-options-list.component.html'
})
export class RegistrationOptionsListComponent {
  @Input('optionsForm') optionsForm: UntypedFormArray;
  @Input('options') public options: Array<IOption>;
}
