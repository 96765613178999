import { Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { ISession } from 'app/shared/models/event.session';



@Component({
    selector: 'app-session-preferences',
    templateUrl: './session-preference-list.component.html'
}) export class SessionsListComponent {
  sessionsWithMultiplePreferences: ISession[];


  @Input('sessions') public sessions: Array<ISession>;
  @Input('sessionPreferencesForm') sessionPreferencesForm: UntypedFormArray;


  ngOnInit() {
    this.sessionsWithMultiplePreferences = this.sessions.filter(s => s.options.length > 1);
  }
}
