import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { IQuestion, QuestionTypes } from 'app/shared/models/question';
import { IAnswer } from 'app/shared/models/attendee';



@Component({
  selector: 'app-registration-question',
  templateUrl: './registration-question.component.html'
}) export class RegistrationQuestionComponent implements OnInit {
  @ViewChild('yesRadio', {static: true}) yesRadio: HTMLInputElement;
  @ViewChild('noRadio', {static: true}) noRadio;


  @Input('question') question: IQuestion;
  @Input() mainForm: NgForm;
  @Input('questionForm') questionForm: UntypedFormGroup;
  @Input() set answers(value: IAnswer[]) {
    if (value == null) return;

    this._answers = value;
    const answer = value.find(x => x.questionId === this.question.id);

    // reset the answer if not found
    if (answer == null && this.answer != null) {
      if (this.yesRadio) { this.yesRadio.checked = false; }
      if (this.noRadio) { this.noRadio.checked = false; }

      this.answer.choiceId = null;
      this.answer.shortAswer = null;
      this.answer.yesNoAnswer = null;
      this.questionForm.get('yesNoAnswer').setValue(null);
    } else {
      this.answer = answer;
    }
  }


  questionTypes = QuestionTypes;
  _answers: IAnswer[];
  answer: IAnswer = {
    questionId: null,
    choiceId: null,
    shortAswer: null,
    yesNoAnswer: null
  };


  constructor() {}


  ngOnInit() {}


  get answers() {
    return this._answers;
  }


  onRadioSelectionChanged(value: boolean) {
    this.question.yesNoAnswer = value;
    this.questionForm.get('yesNoAnswer').setValue(value);
  }


  onYesNoAnswerChanged(value) {
    console.log('onYesNoAnswerChanged: ', value, ', question', this.question);
  }
}
