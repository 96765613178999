import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'accordion',
  template: `<ul class='registration'>
              <ng-content></ng-content>
            </ul>
          `
})
export class Accordion {
  groups: Array<AccordionGroup> = [];

  addGroup(group: AccordionGroup): void {
    this.groups.push(group);
  }

  closeOthers(openGroup: AccordionGroup): void {
    this.groups.forEach((group: AccordionGroup) => {
      if (group !== openGroup) {
        group.isOpen = false;
      }
    });
  }

  removeGroup(group: AccordionGroup): void {
    const index = this.groups.indexOf(group);
    if (index !== -1) {
      this.groups.splice(index, 1);
    }
  }
}

@Component({
  selector: 'accordion-group',
  template: `
                <li [ngClass]="{'is-expanded': isOpen,'is-not-first': !isFirst}">
                  <a class='registration-trigger' name={{headingName}} href="javascript:void(0)">{{heading}}</a>
                  <div class="panel attendees-panel">
                        <ng-content></ng-content>
                  </div>
                </li>
          `
})
export class AccordionGroup implements OnDestroy {
  private _isOpen = false;
  private _isFirst = false;

  @Input() heading: string;
  @Input() headingName = '';
  _isVisible: boolean = true;

  @Input()
  set isOpen(value: boolean) {
    if (value) {
      this._isOpen = true;
    } else {
      this._isOpen = false;
      this._isFirst = false;
    }
  }
  get isOpen() {
    return this._isOpen;
  }

  @Input()
  set openOnlyThisOne(value: boolean) {
    if (value) {
      this._isOpen = true;
      this.accordion.closeOthers(this);
    } else {
      this._isFirst = false;
    }
  }
  

  @Input()
  set isFirst(value: boolean) {
    if (value) {
      this._isFirst = true;
    } else {
      this._isFirst = false;
    }
  }
  get isFirst() {
    return this._isFirst;
  }
  
  @Input()
  set isVisible(value: boolean) {
    if (value) {
      this._isVisible = true;
    } else {
      this._isVisible = false;
    }
  }
  get isVisible() {
    return this._isVisible;
  }

  constructor(private accordion: Accordion) {
    this.accordion.addGroup(this);
  }

  ngOnDestroy() {
    this.accordion.removeGroup(this);
  }

  toggleOpen(event: MouseEvent): void {
    event.preventDefault();
    this.isOpen = !this.isOpen;
  }
}
