import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NotificationsService } from './notifications.service';
import {Message, MessageService} from 'primeng/api';
import {Subscription} from 'rxjs/internal/Subscription';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [MessageService]
})
export class NotificationsComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.notificationsService.notificationChange
      .subscribe((notification: Message) => {
        this.messageService.add(notification);
        this.changeDetectorRef.detectChanges();
      });
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
